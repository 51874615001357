/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        // enable touch support for desktop nav
        // changed to mousedown from touchstart so it work everywhere

        function touchHideDropdown(e) {
          dropdown = $(".menu-item-has-children.open");
          if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0) {
            if (!$(".top-navigation-search-container input.search-box").val()) {
              $(".menu-item-has-children")
                .removeClass("open")
                .find(".sub-menu")
                .css({
                  "max-height": ""
                });
              $("document").unbind("mousedown", touchHideDropdown);
            }
          }
        }

        $("#menu-main-menu .menu-item-has-children>a").on("mousedown", function (e) {
          if (
            !$(this)
            .parent()
            .hasClass("open") &&
            Sage.common.breakpoint.value >= 2
          ) {
            e.preventDefault();
            $(this)
              .parent()
              .addClass("open")
              .find(".sub-menu")
              .css({
                "max-height": "1000px"
              });
            $(document).bind("mousedown", touchHideDropdown);
          }
        });

        // mobile nav functionality
        $("#menu-main-menu .menu-item-has-children").on(
          "mousedown",
          ".expand",
          function (e) {
            $(e.srcElement)
              .toggleClass("open")
              .siblings(".sub-menu")
              .toggleClass("open");
          }
        );



        // video modals \\
        if ($('.video_modal').length) {
          $('.video_modal').each(function () {
            $(this).on(
              "click",
              function (e) {
                e.preventDefault();
                $('#videoIframe').attr('src', 'https://fast.wistia.net/embed/iframe/' + $(this).data('vidid') + '?videoFoam=true' + '&googleAnalytics=false');
                $("#video-modal").modal("show");
              }
            );
          });
          //clear modal on close
          $('#video-modal').on('hidden.bs.modal', function () {
            $('#videoIframe').attr('src', '');
          });
        }

        var video = document.getElementById("native-frame");
          function stopVideo(){
            video.pause();
            video.currentTime = 0;
          }
          function playVideo(){
            video.load();
          }

        $(".native-video-modal").on(
          "click",
          function (e) {
            $("#native-video-modal").modal("show");
            playVideo();
          }
        );
        $('#native-video-modal').on('hidden.bs.modal', function () {
          stopVideo();
        });
        





        // Add target = _blank to external & pdf links
        $('a[href$=".pdf"]').each(function (i) {
          $(this).attr("target", "_blank");
        });
        // Define external link function excluding pdf links
        var currentUrl = window.location.href.substr(
          0,
          window.location.href.indexOf("/", 10)
        );

        $('a[href^="http"]')
          .not('[href^="' + currentUrl + '"]')
          .not('[href$=".pdf"]')
          .each(function () {
            var $href = $(this).attr("href");

            $(this)
              .addClass("external")
              .on(
                "click", {
                  category: "Outbound Link",
                  action: "Click Action",
                  label: $href
                },
                function (e) {
                  e.preventDefault();

                  var exitButton = $("#exit-modal").find(".btn-primary");
                  exitButton.attr({
                    href: $href
                  }).off("click");

                  exitButton.click(function () {
                    $("#exit-modal").modal("hide");
                    Sage.common.gaLogEvent(e);
                  });

                  $("#exit-modal").modal("show");
                }
              );
          });

        function resetStickyBug() {
          if (Sage.common.breakpoint.value >= 2) {
            //header outer height
            hoh = $("header").outerHeight();
            //top distance
            td = $(document).scrollTop();
            if (td >= hoh) {
              $(".sticky-bug").css({
                position: "fixed",
                top: 0
              });
            } else {
              $(".sticky-bug").css({
                position: "",
                top: ""
              });
            }
          }
        }
        // resetStickyBug();
        // $(window).on('resize scroll', function() {
        //   resetStickyBug();
        // });

        //function hide search on click outside form area
        function hideSearch(e) {
          var searchContainer = $(".top-navigation-search-container");
          if (
            !searchContainer.is(e.target) &&
            searchContainer.has(e.target).length === 0
          ) {
            if (!$(".top-navigation-search-container input.search-box").val()) {
              $("input.search-box, button.search-button").removeClass("active");
              $(".top-sub-nav>span").removeClass("active");
              $("document").unbind("click", hideSearch);
            }
          }
        }
        // show search if it has value
        if ($(".top-navigation-search-container input.search-box").val()) {
          $("input.search-box, button.search-button").addClass("active");
        }
        // show search box
        $(".top-navigation-search-container button.search-button").on(
          "click",
          function (e) {
            if (!$(this).hasClass("active")) {
              e.preventDefault();
              $(".top-sub-nav>span").addClass("active");
              $("input.search-box,button.search-button").addClass("active");
              $("input.search-box").focus();
              $(document).bind("click", hideSearch);
            }
          }
        );

        //init responsive tabbed content
        $('.responsive-tabs').responsiveTabs({
          //startCollapsed: 'accordion',
          startCollapsed: false
        });

        $(".r-tabs-accordion-title").on(
          "click",
          function (e) {
            $.scrollTo($('.r-tabs'), 125, {
              offset: -40
            });
          });



        // hide/show design study overlay
        $(".view-study-design").on(
          "click",
          function (e) {
            $(".study-design-overlay").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );

        $(".view-polyp-overlay").on(
          "click",
          function (e) {
            $(".polyp-overlay").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );

       
        $(".view-sub-congestion").on(
          "click",
          function (e) {
            $(".congestion-overlay").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );
        $(".view-sub-grade").on(
          "click",
          function (e) {
            $(".grade-overlay").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );
        $(".view-sub-snot22").on(
          "click",
          function (e) {
            $(".snot22-overlay").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );

        $(".view-sub-surgery-polyp").on(
          "click",
          function (e) {
            $(".surgery-overlay-polyp").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );

        $(".view-sub-surgery-snot").on(
          "click",
          function (e) {
            $(".surgery-overlay-snot").fadeIn();
            $("body").css({
              overflow: "hidden"
            });
            $("header").css({
              display: "none"
            });
            $("#isi").css({
              display: "none"
            });

          }
        );
        
        $(".close-study-design").on("click", function (e) {
          $(".study-design-overlay, .polyp-overlay, .congestion-overlay, .grade-overlay, .snot22-overlay, .surgery-overlay-polyp, .surgery-overlay-snot").fadeOut(100);
          $("body").css({
            overflow: "visible"
          });
          $("header").css({
            display: "block"
          });
          $("#isi").css({
            display: "block"
          });
        });

        // requires .videoWrapper style
        // change static sized iframe video to responsive sized ( add checks to apply for any other than Youtube)
        if (
          $(
            "iframe[src^='http://www.youtube.com'], iframe[src^='https://www.youtube.com']"
          ).length
        ) {
          $(
              "iframe[src^='http://www.youtube.com'], iframe[src^='https://www.youtube.com']"
            )
            .removeAttr("height")
            .removeAttr("width")
            .wrap("<div class='videoWrapper'></div>");
        }


        // ANIMATION TRIGGER FOR PURPLE BANNERS \\
        function initAnimation() {
          elements = document.querySelectorAll('.banner-section');
          windowHeight = window.innerHeight;
          isiBar = document.getElementById('isi_sticky');
        }

        function checkPosition() {
          var isiHeight = isiBar.offsetHeight;
          for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            var positionFromTop = elements[i].getBoundingClientRect().top;

            if (positionFromTop - windowHeight + isiHeight <= 0) {
              element.classList.add('anim-slide-up');
            }
          }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', initAnimation);

        initAnimation();
        checkPosition();


        //Sage.common.SvgPolyfill.init('.grow, #house1, g[data-name="Box1"]');

        // SvgPolyfill.init('.grow, #house1, g[data-name="Box1"]');

        // Uncomment this section if using breakpoints in JavaScript.
        // Update breakpoint value cache on window resize.
        $(window)
          .resize(function () {
            Sage.common.breakpoint.refreshValue();
            //console.log(Sage.common.breakpoint.value);
          })
          .resize();

        //RESPONSIVE TABLE FIXER
        //UNCOMMENT THIS SECTION IF YOU WANT RESPONSIVE TABLES
        Sage.common.responsiveTable();

        //To use sticky footer functionality:
        //Uncomment ISI include in footer.php template
        //Uncomment line in main.scss to include _sticky-footer.scss
        //Uncomment this line:
        Sage.common.stickyISI();
      },

      breakpoint: {
        refreshValue: function () {
          this.value = window
            .getComputedStyle(document.querySelector("body"), ":before")
            .getPropertyValue("content")
            .replace(/['"]+/g, "");
        }
      },

      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },

      SvgPolyfill: {
        /* SVG Polyfill for svg animations for unsupported browsers
          USAGE: uncoment and fill in SvgPolyfill.init(); with the appropreate selectors
          EXAMPLE: SvgPolyfill.init('.grow, #house1, g[data-name="Box1"]');
        */
        init: function (selectors) {
          // Only init for Edge and MSIE
          if (
            navigator.userAgent.indexOf("Edge") === -1 &&
            navigator.userAgent.indexOf("MSIE") === -1 &&
            navigator.userAgent.indexOf("Trident") === -1
          ) {
            return;
          }
          // Cache reference to SVG sub-elements via provided selectors
          this.elements = document.querySelectorAll(selectors);
          // Set up interval to monitor and update elements
          setInterval(this.transformEach.bind(this), 10);
        },
        setTransform: function (element) {
          var style = getComputedStyle(element),
            matrix = style
            .getPropertyValue("transform")
            .match(
              /\(([0-9\.]+),\s+([0-9\.]+),\s+([0-9\.]+),\s+([0-9\.]+),\s+([0-9\.]+),\s+([0-9\.]+)\)/
            ),
            transformOrigin = style
            .getPropertyValue("transform-origin")
            .match(/([0-9\.]+).*?([0-9\.]+)/);
          // Remove first match from matches (copy of entire string)
          matrix.shift();
          transformOrigin.shift();
          // Compute translate parameters dynamically
          matrix[4] = (1 - matrix[0]) * transformOrigin[0];
          matrix[5] = (1 - matrix[3]) * transformOrigin[1];
          // Recompile matrix as string
          matrix = "matrix(" + matrix.join() + ")";
          element.setAttribute("transform", matrix);
        },
        transformEach: function () {
          Array.prototype.forEach.call(this.elements, this.setTransform);
        }
      },

      responsiveTable: function () {
        if ($("table").length) {
          // just get the user created tables
          $table = $("table").not(".crayon-table, .table-copay");

          //first fix any tables without theads
          $($table).each(function () {
            $hasHead = $("thead td, thead th", this).length;
            if (!$hasHead) {
              $(this)
                .prepend("<thead></thead>")
                .find("tr:first")
                .prependTo($("thead", this));
            }
          });

          //second update tables to have data attrs
          $($table).each(function () {
            $hasHead = $("thead td, thead th", this).length;
            $col_titles = [];

            if ($hasHead) {
              //make sure our current table has what we need to get started.
              // cache our column titles (include td for bad html)
              $(this)
                .find("th, td")
                .each(function () {
                  $content = $(this).text() + ": ";
                  $col_titles.push($content);
                });

              // add our column titles to data attrs on each tr>td
              $(this)
                .find("tr")
                .each(function () {
                  $row = $(this);
                  $row.children("td").each(function (key) {
                    $(this).attr("data-label", $col_titles[key]);
                  });
                });

              $(this)
                .find("tr td:nth-child(4)")
                .each(function () {
                   $(this).attr("data-label", 'XHANCE 372 mcg BID (N=161) n (%):');
                });

            }
          });
        }
      },

      stickyISI: function () {
        var scrollTopSave = 0;
        var expanded = false;
        var expanding = false;

        var expandLink = $(".expand_isi"); // The Link that will trigger the ISI expansion
        var cloneTarget = $(".clone_target");
        var stickyContentTarget = $(".sticky_content"); // The container the cloned content will be appended to
        var staticElement = $("#isi-container"); // The highest level container of the ISI at the bottom of the page. Not the sticky part
        var stickyElement = $("#isi_sticky"); // The top level container of the sticky section
        var scroller = cloneTarget.clone();

        function hideShow(stickyElement, staticElement) {
          var isiScrollContainerSelector = ".isi-wrapper"; // The section that should scroll while the footer is stuck
          var clonedElement = stickyElement;
          var scroll = $(window).scrollTop();
          if (
            stickyElement.offset().top > staticElement.offset().top ||
            expanding
          ) {
            //console.log('is on screen');
            clonedElement.removeClass("show");
            //throws error in ie11
            // var page_url = window.location.href;
            // if (page_url.includes("/importantSafetyInformation.html") === false) {
            //   $(".isi-header").show();
            // } else {
            //   $(".isi-header").hide();
            // }
            $(".isi-header").show();
            $(".expand_isi_text").hide();
          } else {
            $(".expand_isi_text").show();
            $(".isi-header").hide();
            //console.log('is not on screen');
            if (!clonedElement.hasClass("show")) {
              $(isiScrollContainerSelector).scrollTop(0);
            }
            clonedElement.addClass("show");
            /*if (scroll >= 500) {
              //shrink sticky isi on scroll
              $("#isi_sticky").addClass("shrink");
            }*/
            $(".clone_target").removeClass("expanded");
            expanded = false;
          }
        }

        function toggleISI(cloneTarget) {
          var offset = cloneTarget.offset();
          offset.top -= 20;
          offset.left -= 20;

          if ($("body").hasClass("home") && Sage.common.breakpoint.value >= 2) {
            offset.top -= 150;
          }

          if (!expanded) {
            scrollTopSave = $(document).scrollTop();
            expanded = true;
            expanding = true;
            cloneTarget.addClass("expanded");
            $("html, body").animate({
                scrollTop: offset.top,
                scrollLeft: offset.left
              },
              275,
              function () {
                expanding = false;
              }
            );
          } else {
            expanded = false;
            cloneTarget.removeClass("expanded");
            $("html, body").animate({
                scrollTop: scrollTopSave,
                scrollLeft: 0
              },
              275
            );
          }
        }

        jQuery.extend({
          getQueryParameters: function (str) {
            return (str || document.location.search)
              .replace(/(^\?)/, "")
              .split("&")
              .map(
                function (n) {
                  return (n = n.split("=")), (this[n[0]] = n[1]), this;
                }.bind({})
              )[0];
          }
        });

        //run onload
        function scrollVisible(element) {
          var docViewTop = $(window).scrollTop();
          var isiHeight = $("#isi_sticky").height();
          var docViewBottom = docViewTop + $(window).height() - isiHeight;
          var elemTop = element.offset().top;
          var elemHeight = element.height();
          return docViewBottom > elemTop + elemHeight * 0.2;
        }

        /**
         * Generic function to handle slide up scroll triggered animations
         *
         */
        if (!Modernizr.touchevents && !Modernizr.pointerevents) {
          var scrollPosTriggers = $("body").find(".slide-up-anim");

          scrollPosTriggers.each(function () {
            if (!scrollVisible($(this))) {
              $(this).removeClass("in");
            }
          });

          if (scrollPosTriggers.length > 0) {
            $(window).on("scroll", function (e) {
              scrollPosTriggers.each(function () {
                // Animate content when we see 20% of the element
                if (scrollVisible($(this))) {
                  $(this).addClass("in");
                  // Remove shown element from the jquery object so e don't hvae to check again
                  scrollPosTriggers = scrollPosTriggers.not($(this));
                }
              });
            });
          }
        }

        // Add ?sticky-isi=false  to the URL to remove for screenshots
        if ($.getQueryParameters()["sticky-isi"] === "false") {
          return;
        }

        scroller.removeClass("clone_target").removeAttr("id");
        stickyElement.css("bottom", 0);
        stickyContentTarget.append(scroller);
        hideShow(stickyElement, staticElement);

        $(window).on("scroll resize", function () {
          hideShow(stickyElement, staticElement);
        });

        expandLink.click(function () {
          toggleISI(cloneTarget);
        });
        //stickyISI end
      }
    },

    //page specific scripts
    home: {
      init: function () {
        // SCROLL VARS
        var count = 120,
          imgElm = $("#device img.sequencer"),
          path = "wp-content/themes/xhance-2018/dist/images/rotation/",
          ext = "png",
          paths = [],
          activeHeight = $("#height_time").outerHeight(),
          headerHeight = $("header").outerHeight(),
          activeArea = activeHeight + headerHeight,
          preload = [];
        // BUILD IMAGE LIST BASED ON VARS
        for (var i = 1; i <= count; i++) {
          paths.push(path + i + "." + ext);
        }
        // PRELOAD
        for (var n = 1; n < paths.length; n++) {
          preload[n] = new Image();
          preload[n].src = paths[n];
        }
        // console.log(preload);
        //SCROLL FUNCTIONALITY
        $(window).scroll(function () {
          windowHeight = $(window).height();
          currentScroll = $(window).scrollTop();
          percentageScroll =
            (100 * currentScroll) / (activeArea - windowHeight);
          index = Math.round((percentageScroll / 100) * count);
          if (index <= count) {
            imgElm.attr("src", paths[index]);
          }
        });

        function setBgHeight() {
          if (Sage.common.breakpoint.value < 2) {
            $('.desktop-device-bg, .desktop-img-bg').css({
              'height': ''
            });
            return;
          }

          var isiHeight = $('#isi_sticky').height();
          var headerHeight = $('header').height();
          var windowHeight = window.innerHeight;

          var contentHeight = windowHeight - headerHeight - isiHeight;

          // console.log("Content: " + contentHeight);
          // console.log("Header: " + headerHeight);
          // console.log("ISI: " + isiHeight);
          // console.log("Window: " + windowHeight);

          $('.desktop-device-bg, .desktop-img-bg').css({
            'height': contentHeight + 'px'
          });

        }
        setBgHeight();

        $(window).on('resize', setBgHeight);


        // Create Video Element and Loop \\
        var hero = document.getElementById("hero_vid_contain");

        video = document.createElement('video');
        video.src = "/wp-content/themes/xhance-2018/dist/video/home_video_8-17-2020.mp4";
        video.type = 'video/mp4';
        video.loop = true;
        video.autoplay = true;
        video.muted = true;
        video.preload = 'auto';
        video.load();
        video.play();

        hero.appendChild(video);
      }

    },

    exhalation_delivery_systems: {
      init: function () {
        var videoPlayed = false;

        /*var outputCanvas = document.getElementById("output"),
          output = outputCanvas.getContext("2d"),
          bufferCanvas = document.getElementById("buffer"),
          buffer = bufferCanvas.getContext("2d"),
          video = document.getElementById("eds-video"),
          width = outputCanvas.width,
          height = outputCanvas.height,
          interval;*/

        function processFrame() {
          buffer.drawImage(video, 0, 0);
          // this can be done without alphaData, except in Firefox which doesn't like it when image is bigger than the canvas
          var image = buffer.getImageData(0, 0, width, height),
            imageData = image.data,
            alphaData = buffer.getImageData(0, height, width, height).data;
          for (var i = 3, len = imageData.length; i < len; i = i + 4) {
            imageData[i] = alphaData[i - 1];
          }
          output.putImageData(image, 0, 0, 0, 0, width, height);
        }

        /*function playVideo() {
          if (!videoPlayed && $('.eds-video-inner-container').isInViewport()) {
            var edsVideo = document.getElementById('eds-video');
            edsVideo.addEventListener('play', function() {
              clearInterval(interval);
              interval = setInterval(processFrame, 40);
            }, false);
            edsVideo.play();

            videoPlayed = true;
          }
        }
        playVideo();
        $(window).on('resize scroll', function() {
          playVideo();
        });*/

        function startBottleAnim() {
          if ($('.eds-video-inner-container').isInViewport()) {
            /*setTimeout(function() {
              $('.bottle').fadeIn();
            }, 400);*/
            $('.bottle').addClass('animate_start');
            setTimeout(function () {
              $(".eds-video-message1").fadeIn();
              setTimeout(function () {
                $(".eds-video-message3").fadeIn();
                setTimeout(function () {
                  $(".eds-video-message2").fadeIn();
                }, 1500);
              }, 1200);
            }, 1000);
          }
        }
        startBottleAnim();

        $(window).on("resize scroll", function () {
          startBottleAnim();
        });

        $(".eds-touch-point").click(function () {
          var num = $(this).data("num");
          if (num) {
            $("body").css({
              overflow: "hidden"
            });
            $(".eds-image-messages").fadeIn();
            $(".eds-image-message" + num).fadeIn();
          }
        });

        $(".eds-close").click(function () {
          var num = $(this).data("num");
          if (num) {
            $("body").css({
              overflow: "visible"
            });
            $(".eds-image-message" + num).fadeOut();
            $(".eds-image-messages").fadeOut();
          }
        });

        // Only display EDS video on non-touch devices + medium and up breakpoint
        function shouldDisplayVideo() {
          var hasTouch = "ontouchstart" in window;
          if (hasTouch) {
            $(".exhalation-delivery-system .main").addClass("touchevents");
          }
        }
        shouldDisplayVideo();
      }
    },
    resources: {
      init: function () {
        // Patient Brochure \\
        var checklist = document.getElementById('patientBrochure');
        var ctaButton = checklist.querySelectorAll('.btn')[0];
        var radios = checklist.getElementsByTagName('input');
        Array.prototype.forEach.call(radios, function (radio, index) {
          radio.addEventListener("click", function () {
            checklist.className = radio.value;
            ctaButton.href = radio.dataset.url;
          });
        });

      }
    },

    get_updates: {
      init: function () {
        // check location hash and update form
        var hash = window.location.hash;
        if (hash === "#reqrep") {
          $(".form-container").removeClass("active");
          $(".form-selection").removeClass("active");
          $(".form-container[data-form-name='schedule-visit-form']").addClass(
            "active"
          );
          $(".form-selection[data-target='schedule-visit-form']").addClass(
            "active"
          );
        }

        MktoForms2.whenReady(function (form) {
          form.onSuccess(function (values, followUpUrl) {
            $(".dynamic-form").hide();
            $(".form-confirmation").show();
            $(window).scrollTo($(".form-confirmation").offset().top);
            return false;
          });
        });
      }
    },

    frequently_asked_questions: {
      init: function () {
        $(".panel-collapse").on(
          "shown.bs.collapse",
          function (e) {
            var heading = $(this).siblings(".panel-heading");
            if (!visibleAndNearTopOfPage(heading)) {
              $.scrollTo($(this), 125, {
                offset: -40
              });
            }
          }
        );

        $(".panel-collapse").on(
          "show.bs.collapse hide.bs.collapse",
          function () {
            $(this)
              .siblings(".panel-heading")
              .toggleClass("active");
          }
        );
      }
    },

    experience_survey: {
      init: function () {
        
      }
    },

    xhance_xtended_patient_support: {
      init: function () {
        //Init data for phrama search
        //data_init();
        //Locator functions
        

      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $.fn.isInViewport = function () {
    var isiHeight = $("#isi_sticky").height();

    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height() - isiHeight;

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };
})(jQuery); // Fully reference jQuery after this point.
