/*
 * Retrive utm querystring values, save in cookie and populate hidden form fields.
 *
 * Updated May 1, 2019 by fingerpaint marketing
 *
 * This script should be placed in the body of the page below the form and before the </body> tag
 * utmUrlCookiePart1 and utmUrlCookiePart2 must be called, see bottom of script
 * update utmUrlCookiePart2 and utmUrlCookiePart2 to match your querystring and form field names
 *
 */
console.log('utmcookie');

// Leave this as true to always use querystring values if they exist, if no querystring will attempt to get cookie values
var utmUseLatestValues = true; // set this to false to use cookie values if they exist (if false, will not check querystring first).

//function to grab params from cookie
function getCookie(param_name) {
    var i, x, y, cookie = document.cookie.split(";");
    for (i = 0; i < cookie.length; i++) {
        x = cookie[i].substr(0, cookie[i].indexOf("="));
        y = cookie[i].substr(cookie[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === param_name) {
            return unescape(y);
        }
    }
}

// function to create cookie
function utmSetCookie(param_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var base_url = window.location.hostname;
    base_url = base_url.replace('www.', '');
    // console.log(base_url);
    var c_value = escape(value) + ((exdays == null) ? "" : "; domain=" + base_url + "; path=/; expires=" + exdate.toUTCString());
    document.cookie = param_name + "=" + c_value;
}

// function to check if cookie exists and, if so, run the utmSetCookie function
function checkCookie(param_name, param_url_name) {

    var param_value = getCookie(param_name);
    if ((param_value !== null && param_value !== "" && param_value !== "undefined") && utmUseLatestValues === false) {
        // this means the param name/value pair exists - and we don't want to use latest
    } else {
        // this means the param name/value pair does not exist - so create it
        // grab values from URL
        var pageURL = window.location.search.substring(1);
        var URLVariables = pageURL.split('&');
        for (var i = 0; i < URLVariables.length; i++) {
            var parameterName = URLVariables[i].split('=');
            if (parameterName[0] === param_url_name) {
                // filter out "#" in case that is in the last URL param
                param_value = parameterName[1].split("#")[0];
            }
        }

        if (param_value !== "undefined" && param_value !== "" && param_value != null) {
            // console.log(param_name, param_value);
            // create cookie
            utmSetCookie(param_name, param_value, 365);
        }
    }
}


// function to setup the parameters and save the cookie values
function utmUrlCookiePart1() {
    // setup list/array of parameters desired. names on right should match querystring names
    var param_names = new Array(
        'utmSource;utm_source',
        'utmMedium;utm_medium',
        'utmCampaign;utm_campaign',
        'utmAdGroup;utm_adgroup',
        'utmTerm;utm_term',
        'utmContent;utm_content'
    );


    // loop through all params and create cookie
    for (var i = 0; i < param_names.length; i++) {
        var param_object = param_names[i].split(";"); // split out the cookie name and url name
        var param_name = param_object[0];
        var param_url_name = param_object[1];
        // start the cookie creation
        checkCookie(param_name, param_url_name);
    }
}



// function to grab cookie params
function mGetCookie(param_name) {
    var i, x, y, cookie = document.cookie.split(";");
    for (i = 0; i < cookie.length; i++) {
        x = cookie[i].substr(0, cookie[i].indexOf("="));
        y = cookie[i].substr(cookie[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === param_name) {
            return unescape(y);
        }
    }
}

// function to check if cookie exists and, if so, fill out the corresponding form fields
function mCheckCookie(param_name, param_field_name) {
    var param_value = mGetCookie(param_name);
    if (param_value !== null && param_value !== "" && param_value !== "undefined") {
        try {
            var obj1 = document.getElementsByName(param_field_name);
            obj1[0].value = param_value;
            return true;
        } catch (err) {
            return false;
        }
    }
    return false;
}

// function to setup parameters and begin cookie value insertion into marketo form
function utmUrlCookiePart2() {
    // setup list/array of parameters desired. names on right should match hidden form field names
    var param_names = new Array(
        'utmSource;utmsource',
        'utmMedium;utmmedium',
        'utmCampaign;utmcampaign',
        'utmAdGroup;utmadgroup',
        'utmTerm;utmterm',
        'utmContent;utmcontent'
    );

    // loop through all params and create cookie
    for (var i = 0; i < param_names.length; i++) {
        var param_object = param_names[i].split(";"); // split out the cookie name and url name
        var param_name = param_object[0];
        var param_field_name = param_object[1];
        // start the cookie creation
        mCheckCookie(param_name, param_field_name);
    }
}

// utmUrlCookiePart1 will grab values from the querystring and save them in cookies
utmUrlCookiePart1();

// utmUrlCookiePart2 will retrive values from the cookies and populate the hidden form fields - should be in the onload
try {
    // attempt for Marketo form
    MktoForms2.whenReady(function (form) {
        utmUrlCookiePart2();
    });
} catch (err) {
    // if error on Marketo form, try loading for regular form.
    utmUrlCookiePart2();
}